import axios from "axios"

export const createFinanceOrder = (quoteId, email, phone, productId, firstName, lastName, shippingMethod, shippingAddress, variantId) => {

  const data = {
    quoteId: quoteId,
    email: email,
    phone: phone,
    firstName: firstName,
    lastName: lastName,
    shippingMethod: shippingMethod,
    shippingAddress: shippingAddress,
    productId: productId,
    variantId: variantId
  }

  const config = {
    baseURL: `${process.env.STRIPE_SVC_BACKEND_BASE_URL}`,
    auth: {
      username: `${process.env.STRIPE_SVC_BACKEND_USERNAME}`,
      password: `${process.env.STRIPE_SVC_BACKEND_PASSWORD}`
    },
  };

  return axios.post(`/api/v1/ivendi-payments/order`, data, config);
}
