import {rawPrice as _rawPrice} from "./pricesHelper";

export const convertIVendiResponse = data => {

  let results = [];
  if(data.hasQuoteResults || data.HasQuoteResults) {
    const QuoteResults = data.QuoteResults
    const QuoteResultsItems = QuoteResults ? QuoteResults.filter(item => item.hasResults && !item.hasErrors) : [];
    if (QuoteResultsItems && !!QuoteResultsItems.length) {
      const ResultsItems = [];
      QuoteResultsItems.forEach(item => {
        const Results = item.Results;
        const newResultItems = Results ? Results.filter(resultItem => resultItem.hasProductGroup && !resultItem.hasErrors) : [];
        if (!!newResultItems.length) {
          ResultsItems.push(...newResultItems)
        }
      })
      if (!!ResultsItems.length) {
        const ProductGroupsItems = [];
        ResultsItems.forEach(rsItem => {
          const ProductGroups = rsItem.ProductGroups;
          const newProductGroupsItems = ProductGroups ? ProductGroups.filter(productGroupItem => !productGroupItem.hasErrors && productGroupItem.hasProductQuote) : [];
          if (!!newProductGroupsItems.length) {
            ProductGroupsItems.push(...newProductGroupsItems)
          }
        })
        if(!!ProductGroupsItems.length){
          const ProductQuotesItems = [];
          ProductGroupsItems.forEach(pgItem => {
            const ProductQuotes = pgItem.ProductQuotes;
            const newProductQuotesItems =  ProductQuotes ? ProductQuotes.filter(a => {
              if (a.hasErrors && a.Errors && a.Errors.PublicErrors) {
                a.Errors.PublicErrors.forEach(error => {
                  console.log("Finance conditions not fulfilled because of", error, a.ProductName)
                  // error.Number === 502 -> error.Message Cash Deposit below minimum amount of x (usually 10%).
                  // error.Number === 517 -> error.Message Motor Vehicle Condition of Used not allowed x (usually 10%).
                  // error.Number === 504 -> error.Message Below minimum term of x (could be 25 & 36).
                })
              }
              return !a.hasErrors && a.Figures !== null
            }) : [];

            if(!!newProductQuotesItems){
              ProductQuotesItems.push(...newProductQuotesItems)
            }
          })
          return ProductQuotesItems.sort((first, second) => first.Figures.RegularPayment - second.Figures.RegularPayment)

        }
      }
    }
  }

  return results;
}
/**
 * If you want to get dealershipId you need to add "ClientName": "finance-widget" to the DealershipEnrichment object in request body
 * @param data
 * @returns {*}
 */
export const extractDealershipId = data => {
  return data.DealershipId
}

const MIN_PRODUCT_PRICE_FINANCE_INCREASE_IN_PERCENTAGE = 103
const MAX_PRODUCT_PRICE_FINANCE_INCREASE_IN_PERCENTAGE = 115

export const getMinFinancingAccessoryValue = (price, months) => Math.floor(price * MIN_PRODUCT_PRICE_FINANCE_INCREASE_IN_PERCENTAGE / months / 100);
export const getMaxFinancingAccessoryValue = (price, months) => Math.ceil(price * MAX_PRODUCT_PRICE_FINANCE_INCREASE_IN_PERCENTAGE / months/ 100);

export const getFinanceOffer = product => {
  const offer = {
    offerAvailable:false,
    annualDistance: process.env.IVENDI_DEFAULT_ANNUAL_DISTANCE,
    termInMonths: process.env.IVENDI_DEFAULT_TERM_IN_MONTHS,
    cashDeposit: Math.ceil((0.1 * _rawPrice(product.price)) / 100) * 100,
  }
  let productPromotions
  let termInMonths, annualDistance, cashDeposit
  if (product.nodeType === "SimpleProduct") {
    productPromotions = product.beforeAddToBasketNotification.productPromotions
  }
  if (product.nodeType === "VariableProduct") {
    productPromotions = product.genericProductData.beforeAddToBasketNotification.productPromotions
  }
  if (productPromotions == null) {
    return offer
  }
  productPromotions.forEach(promotion => {
    if (promotion.offerType === 'Finance Offer' && promotion.financeOptionsAdjustment !== null) {
      termInMonths = promotion.financeOptionsAdjustment?.termInMonths
      annualDistance = promotion.financeOptionsAdjustment?.annualDistance
      cashDeposit = promotion.financeOptionsAdjustment?.cashDeposit
    }
  })

  if (termInMonths !== null && termInMonths > 0) {
    offer.offerAvailable = true
    offer.termInMonths = termInMonths
  }
  if (annualDistance !== null && annualDistance > 0) {
    offer.offerAvailable = true
    offer.annualDistance = annualDistance
  }
  if (cashDeposit !== null && cashDeposit >= 0) {
    offer.offerAvailable = true
    offer.cashDeposit = cashDeposit
  }
  return offer
}

export const convertDateToUkFormat = (registrationDate) => {
  return new Date(`${registrationDate}`).toLocaleDateString("en-GB", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
}).split(',')[0]
}

