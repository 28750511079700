import * as types from "./types"
import { combineReducers } from "redux"
import _omit from "lodash/omit"

const product = ( state = null, { type, payload }) => {
  switch (type) {
    case types.SET_FINANCING_PRODUCT:
      return payload.product
    case types.RESET:
      return null
    default:
      return state
  }
}

const quote = ( state = null, { type, payload}) => {
  switch (type){
    case types.SET_FINANCING_PRODUCT:
      return payload.quote
    case types.RESET:
      return null
    default:
      return state
  }
}

const quoteWithAccessories = ( state = null, { type, payload}) => {
  switch (type){
    case types.SET_FINANCING_PRODUCT:
    case types.RESET:
      return null
    case types.FETCH_FINANCING_WITH_ACCESSORIES_SUCCESS:
      if(payload && !!payload.length){
        return payload[0] //get best offer
      }
      return null
    default:
      return state
  }
}

const isFetchingFinalFinance = ( state = false, { type, payload}) => {
  switch (type){
    case types.RESET:
    case types.SET_FINANCING_PRODUCT:
    case types.FETCH_FINANCING_WITH_ACCESSORIES_FAILURE:
    case types.FETCH_FINANCING_WITH_ACCESSORIES_SUCCESS:
      return false
    case types.FETCH_FINANCING_WITH_ACCESSORIES_REQUEST:
      return true
    default:
      return state
  }
}

const queryAnnualDistance = (state = null, {type,payload}) => {
  switch (type){
    case types.SET_FINANCING_PRODUCT:
      return payload.queryAnnualDistance
    case types.RESET:
      return null
    default:
      return state
  }
}

const queryTermInMonths = (state = null, {type,payload}) => {
  switch (type){
    case types.SET_FINANCING_PRODUCT:
      return payload.queryTermInMonths
    case types.RESET:
      return null
    default:
      return state
  }
}

const queryCashDeposit = (state = null, {type,payload}) => {
  switch (type){
    case types.SET_FINANCING_PRODUCT:
      return payload.queryCashDeposit
    case types.RESET:
      return null
    default:
      return state
  }
}

const accessories = (state = {}, { type, payload}) => {
  switch (type){
    case types.REMOVE_ALL_ACCESSORIES:
    case types.SET_FINANCING_PRODUCT:
    case types.RESET:
      return {}
    case types.ADD_ACCESSORY: {
      const { product, variant } = payload
      return { ...state, [product.id]: { product, variant } }
    }
    case types.REMOVE_ACCESSORY: {
      const { product } = payload
      return _omit(state, [product.id])
    }
    default:
      return state
  }
}

const email = (state = null, {type,payload}) => {
  switch (type){
    case types.SET_USER_EMAIL:
      return payload
    case types.SET_FINANCING_PRODUCT:
    case types.RESET:
      return null
    default:
      return state
  }
}

const phone = (state = null, {type,payload}) => {
  switch (type){
    case types.SET_USER_PHONE_NUMBER:
      return payload
    case types.SET_FINANCING_PRODUCT:
    case types.RESET:
      return null
    default:
      return state
  }
}

const isProcessing = (state = false, {type}) => {
  switch (type){
    case types.CREATE_ORDER_REQUEST:
      return true
    case types.RESET:
    case types.SET_FINANCING_PRODUCT:
    case types.FINISH_FINANCING_PROCESS:
    case types.CREATE_ORDER_SUCCESS:
    case types.CREATE_ORDER_FAILURE:
      return false
    default:
      return state
  }
}

const isError = (state = false, {type}) => {
  switch (type){
    case types.SET_FINANCING_PRODUCT:
    case types.FINISH_FINANCING_PROCESS:
    case types.CREATE_ORDER_REQUEST:
    case types.RESET:
      return false
    case types.CREATE_ORDER_FAILURE:
      return true
    default:
      return state
  }
}

const isSuccess = (state = false, {type}) => {
  switch (type){
    case types.SET_FINANCING_PRODUCT:
    case types.FINISH_FINANCING_PROCESS:
    case types.CREATE_ORDER_REQUEST:
    case types.RESET:
      return false
    case types.CREATE_ORDER_SUCCESS:
      return true
    default:
      return state
  }
}

const isFetchingAccessories = (state = false, {type}) => {
  switch (type){
    case types.FETCH_ACCESSORIES_FAILURE:
    case types.FETCH_ACCESSORIES_SUCCESS:
      return false
    case types.FETCH_ACCESSORIES_REQUEST:
      return true;
    default:
      return state
  }
}

const productAccessories = (state = [], {type, payload}) => {
  switch (type){
    case types.FETCH_ACCESSORIES_SUCCESS:
      return payload
    case types.FETCH_ACCESSORIES_REQUEST:
    case types.FETCH_ACCESSORIES_FAILURE:
      return []
    default:
      return state;
  }
}

export default combineReducers({
  product, quote, accessories,queryAnnualDistance, queryCashDeposit, queryTermInMonths, email, phone, isProcessing, isError, isSuccess, isFetchingAccessories, productAccessories, quoteWithAccessories, isFetchingFinalFinance
})
