import axios from 'axios';

export const fetchAccessories = (ids = []) => {

  const params = {
    ids: ids.join(",")
  };

  // const params = {
  //   ids
  // }

  const config = {
    baseURL: process.env.ACCESSORIES_SVC_BACKEND_BASE_URL,
    params
  };

  return axios.get(`/api/v1/related-products`, config);
};

export const createAccessoriesOrder = (email, phone,externalOrderId, items) =>{

  const data = {
    email: email,
    phone: phone,
    externalSystemName: process.env.ACCESSORIES_SVC_BACKEND_REFERENCE_SYSTEM_NAME,
    externalOrderId: externalOrderId,
    items: items
  }

  const config = {
    baseURL: `${process.env.ACCESSORIES_SVC_BACKEND_BASE_URL}`,
    auth: {
      username: `${process.env.ACCESSORIES_SVC_BACKEND_USERNAME}`,
      password: `${process.env.ACCESSORIES_SVC_BACKEND_PASSWORD}`
    },
  };

  return axios.post(`/api/v1/ivendi-payments/order-reference`, data, config);
}