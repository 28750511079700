import * as types from './types'
import { createActionType } from "../../utils/redux"
import { FINISH_FINANCING_PROCESS } from "./types"
//state.selectedAnnualDistance, state.selectedTermInMonths, state.selectedCashDeposit
export const setFinancingProduct = (product, quote,queryAnnualDistance, queryTermInMonths, queryCashDeposit) => ({type: types.SET_FINANCING_PRODUCT, payload: { product, quote,queryAnnualDistance, queryTermInMonths, queryCashDeposit } })
export const reset = () => ({type: types.RESET})
export const addAccessory = ( product, variant ) => ({type: types.ADD_ACCESSORY, payload: {product,variant} })
export const removeAccessory = product => ({type: types.REMOVE_ACCESSORY, payload: {product}})
export const removeAllAccessories = () => ({type: types.REMOVE_ALL_ACCESSORIES})
export const setUserEmail = mail => ({type: types.SET_USER_EMAIL, payload: mail})
export const setUserPhoneNumber = phone => ({type: types.SET_USER_PHONE_NUMBER, payload: phone})

export const createOrderRequest = () => ({type: types.CREATE_ORDER_REQUEST});
export const createOrderSuccess = data => ({type: types.CREATE_ORDER_SUCCESS, payload: data});
export const createOrderFailure = error => ({type: types.CREATE_ORDER_FAILURE, payload: error});

export const fetchAccessoriesRequest = () => ({type: types.FETCH_ACCESSORIES_REQUEST});
export const fetchAccessoriesSuccess = data => ({type: types.FETCH_ACCESSORIES_SUCCESS, payload: data});
export const fetchAccessoriesFailure = error => ({type: types.FETCH_ACCESSORIES_FAILURE, payload: error});

export const fetchFinancingWithAccessoriesRequest = () => ({type: types.FETCH_FINANCING_WITH_ACCESSORIES_REQUEST})
export const fetchFinancingWithAccessoriesSuccess = data => ({type: types.FETCH_FINANCING_WITH_ACCESSORIES_SUCCESS, payload: data})
export const fetchFinancingWithAccessoriesFailure = error => ({type: types.FETCH_FINANCING_WITH_ACCESSORIES_FAILURE, payload: error})

export const finishFinancingProcess = () => ({type: types.FINISH_FINANCING_PROCESS})