import { createActionType } from "../../utils/redux"

const namespace = 'finance_'

export const SET_FINANCING_PRODUCT = createActionType(namespace ,'SET_FINANCING_PRODUCT')

export const RESET = createActionType(namespace,'RESET')

export const FETCH_ACCESSORIES_SUCCESS = createActionType(namespace,"FETCH_ACCESSORIES_SUCCESS")
export const FETCH_ACCESSORIES_REQUEST = createActionType(namespace,"FETCH_ACCESSORIES_REQUEST")
export const FETCH_ACCESSORIES_FAILURE = createActionType(namespace,"FETCH_ACCESSORIES_FAILURE")

export const FETCH_FINANCING_WITH_ACCESSORIES_SUCCESS = createActionType(namespace, 'FETCH_FINANCING_WITH_ACCESSORIES_SUCCESS')
export const FETCH_FINANCING_WITH_ACCESSORIES_REQUEST = createActionType(namespace, 'FETCH_FINANCING_WITH_ACCESSORIES_REQUEST')
export const FETCH_FINANCING_WITH_ACCESSORIES_FAILURE = createActionType(namespace, 'FETCH_FINANCING_WITH_ACCESSORIES_FAILURE')

export const ADD_ACCESSORY = createActionType(namespace,'ADD_ACCESSORY')
export const REMOVE_ACCESSORY = createActionType(namespace,'REMOVE_ACCESSORY')
export const REMOVE_ALL_ACCESSORIES = createActionType(namespace,'REMOVE_ALL_ACCESSORIES')

export const SET_USER_EMAIL = createActionType(namespace,'SET_USER_EMAIL')
export const SET_USER_PHONE_NUMBER = createActionType(namespace,'SET_USER_PHONE_NUMBER')

export const CREATE_ORDER_SUCCESS = createActionType(namespace,'CREATE_ORDER_SUCCESS');
export const CREATE_ORDER_REQUEST = createActionType(namespace,'CREATE_ORDER_REQUEST');
export const CREATE_ORDER_FAILURE = createActionType(namespace,'CREATE_ORDER_FAILURE');

export const FINISH_FINANCING_PROCESS = createActionType(namespace, 'FINISH_FINANCING_PROCESS')