import { navigate } from "gatsby";
import * as actions from './actions';
import * as accessoriesAPI from "../../api/accessories"
import * as financeAPI from "../../api/financing"
import * as iVendiAPI from "../../api/iVendi"
import { values as _values } from "lodash/object"
import { rawPrice as _rawPrice } from "../../utils/pricesHelper"
import { convertIVendiResponse } from "../../utils/ivendi"

export const fetchAccessories = () => (dispatch, getState) => {
  const {finance: {product}} = getState();
  const ids = product && product.externalRelatedProducts && product.externalRelatedProducts.products || []
  if(ids.length>0){
    dispatch(actions.fetchAccessoriesRequest())
    accessoriesAPI.fetchAccessories(ids)
      .then(response => {
        const data = response.data;
        dispatch(actions.fetchAccessoriesSuccess(data))
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.fetchAccessoriesFailure(error))
      })
  }
}

export const processFinancingProduct = (product, quote, queryAnnualDistance, queryTermInMonths, queryCashDeposit) => dispatch => {
  dispatch(actions.setFinancingProduct(product,quote, queryAnnualDistance, queryTermInMonths, queryCashDeposit))
  navigate("/finance-checkout-product")
}

export const finalizeProcess = () => (dispatch, getState) => {

  const {finance:{product, quote:{QuoteUID}, accessories, queryAnnualDistance, queryCashDeposit, queryTermInMonths, email, phone, isProcessing, isError, isSuccess}} = getState();
  let firstName = 'Dealer'
  let lastName = 'Website'
  let shippingMethod = 'FREE'
  let shippingAddress
  const savedFormDetailsPayload = getState().basket?.saveFormDetails?.payload
  if (savedFormDetailsPayload !== null) {
    firstName = savedFormDetailsPayload.firstName
    lastName = savedFormDetailsPayload.lastName
    shippingMethod = savedFormDetailsPayload.shippingMethod
    if(shippingMethod === "FLAT_RATE") {
      shippingAddress = {
        address1: savedFormDetailsPayload.addressLine1,
        address2: savedFormDetailsPayload.addressLine2,
        city: savedFormDetailsPayload.city,
        postcode: savedFormDetailsPayload.postcode,
        country: 'GB',
      }
    } else {
      if (shippingMethod === 'FREE') {
        shippingAddress = {
          address1: process.env.SHEPHERDS_BUSH_STREET,
          address2: process.env.SHEPHERDS_BUSH_BRANCH_NAME,
          city: process.env.SHEPHERDS_BUSH_CITY,
          postcode: process.env.SHEPHERDS_BUSH_POST_CODE,
          country: 'GB',
        }
      } else {
        console.error('pick up location not found', savedFormDetailsPayload.pickupLocation)
      }
    }
  }

  dispatch(actions.createOrderRequest())

  financeAPI.createFinanceOrder(QuoteUID,email,phone,product.id, firstName, lastName, shippingMethod, shippingAddress, null).then(response => {
    const data = response.data;
    const { orderId } = data
    const accessoriesList = _values(accessories)
    if(accessoriesList.length >0){
      const items = accessoriesList.map( item => ({productId: item.product.id, variantId: item.variant ? item.variant.id : null}))
      return accessoriesAPI.createAccessoriesOrder(email,phone,orderId,items)
    }else {
      dispatch(actions.createOrderSuccess(data))
      return Promise.resolve({data})
    }
  }).then(response => {
    //const data = response.data;
    dispatch(actions.createOrderSuccess({}))
  }).catch(error => {
    dispatch(actions.createOrderFailure(error))
  })


}

export const getFinalFinancing = (isShippingSelected) => (dispatch, getState) => {

  const {finance:{product: {financingWidgetIvendi:{used,capCode,registrationDate,currentOdometerReading,vrm},price, beforeAddToBasketNotification}, quote:{QuoteUID}, accessories,queryAnnualDistance, queryCashDeposit, queryTermInMonths, } } = getState();

  let extraFee;

  if(beforeAddToBasketNotification.extraFee !== null  ) {
     extraFee = beforeAddToBasketNotification.extraFee.feeValue
  }
  const accessoriesList = _values(accessories)

  if(accessoriesList.length > 0 || isShippingSelected ){
    const accessoriesRestArray = accessoriesList.map(accessory => {
      return {
        price: accessory.variant != null ? accessory.variant.price : accessory.product.price,
        name: accessory.variant != null ? accessory.variant.name : accessory.product.name
      }
    })
    const allInclusivePrice = isShippingSelected !== false ? ( + _rawPrice(price) + extraFee +Number(process.env.SHIPPING_FEE)).toFixed(2) : (+_rawPrice(price) + extraFee).toFixed(2)
    const {finance: {product}} = getState();
    dispatch(actions.fetchFinancingWithAccessoriesRequest())
    if(used){
      dispatch(actions.fetchFinancingWithAccessoriesRequest())
      try {
        iVendiAPI.quotewareRequestForUsedBike(capCode, allInclusivePrice, vrm, registrationDate, currentOdometerReading, queryAnnualDistance, queryTermInMonths, queryCashDeposit, accessoriesRestArray)
            .then(response => {
              const products = convertIVendiResponse(response.data)
              dispatch(actions.fetchFinancingWithAccessoriesSuccess(products))
            }).catch(error => {
          dispatch(actions.fetchFinancingWithAccessoriesFailure(error))
        })
      } catch (error) {
        console.error(error)
        dispatch(actions.fetchFinancingWithAccessoriesFailure(error))
      }
    } else {
      try {
        iVendiAPI.quotewareRequestForNewBike(capCode, allInclusivePrice, queryAnnualDistance, queryTermInMonths, queryCashDeposit, accessoriesRestArray, product.brand === 'cf-moto')
            .then(response => {
              const products = convertIVendiResponse(response.data)
              dispatch(actions.fetchFinancingWithAccessoriesSuccess(products))
            }).catch(error => {
          dispatch(actions.fetchFinancingWithAccessoriesFailure(error))
        })
      } catch (error) {
        console.error(error)
        dispatch(actions.fetchFinancingWithAccessoriesFailure(error))
      }
    }
  }
}
