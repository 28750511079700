import { createSelector } from "reselect"
import { values as _values } from "lodash/object"

const selectFinanceState = state => state.finance

export const getBeforeAddToBasketNotifications = createSelector([selectFinanceState], finance => {
  return _values(finance?.product?.beforeAddToBasketNotifications)
})

export const getAccessories = createSelector([selectFinanceState], finance => {
  return _values(finance?.accessories)
})

export const getProduct = createSelector([selectFinanceState], finance => {
  return finance?.product
})

export const getQuote = createSelector([selectFinanceState], finance => {
    return finance?.quote
  }
)

export const getQuoteWithAccessories = createSelector([selectFinanceState], finance => {
  return finance?.quoteWithAccessories
})

export const getUserEmail = createSelector([selectFinanceState], finance => {
  return finance?.email
})

export const getUserPhoneNumber = createSelector([selectFinanceState],finance => {
  return finance?.phone
})

export const isProcessing = createSelector([selectFinanceState], finance => {
  return finance?.isProcessing
})

export const isError = createSelector([selectFinanceState], finance => {
  return finance?.isError
})

export const isSuccess = createSelector([selectFinanceState], finance => {
  return finance?.isSuccess
})

export const isFetchingAccessories = createSelector([selectFinanceState], finance => {
  return finance?.isFetchingAccessories
})

export const getProductAccessories = createSelector([selectFinanceState], finance => {
  return finance?.productAccessories
})

export const getQueryTermInMonths = createSelector([selectFinanceState], finance => {
  return finance?.queryTermInMonths
})
