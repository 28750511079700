import axios from 'axios';

export const DEFAULT_ANNUAL_DISTANCE = 10000
export const DEFAULT_TERM_IN_MONTHS = 24
export const DEFAULT_CASH_DEPOSIT = 0

export const quotewareRequestForUsedBike = (capCode, cashPrice,vrm, registrationDate, currentOdometerReading ,annualDistance = DEFAULT_ANNUAL_DISTANCE, termInMonths= DEFAULT_TERM_IN_MONTHS, cashDeposit = DEFAULT_CASH_DEPOSIT, accessories = []) => {

  const USERNAME = process.env.IVENDI_USERNAME;
  const QUOTEEUID = process.env.IVENDI_QUOTEEUID;
  const DEALERSHIP_EMAIL = process.env.IVENDI_DEALERSHIP_EMAIL;
  const DEALERSHIP_POSTCODE = process.env.IVENDI_DEALERSHIP_POSTCODE;

  if (vrm != null || capCode != null) {
    const vrmValue = vrm?.replace(/\s/g, '')
    const capCodeValue = capCode?.padEnd(20, " ");

    let accessoriesRequestObject = 'null'
    if (accessories && !!accessories.length) {
      const arrayAccessories = []
      for (let accessory in accessories) {
        let accessoryObject = `
      {
        "GrossPrice":"${accessory.price}",
        "TypeCode": 13,
        "Description": "${accessory.name}",
        "TaxCode": 1
      }`;

        arrayAccessories.push(accessoryObject)
      }

      accessoriesRequestObject = `[${arrayAccessories.join(",")}]`
    }

    const data = `
    {
      "Credentials":{
        "Username":"${USERNAME}"
      },
      "DealershipEnrichment":{
        "Email": "${DEALERSHIP_EMAIL}",
        "Postcode": "${DEALERSHIP_POSTCODE}"
      },
      "QuoteRequests":[
        {
          "QuoteeUID":"${QUOTEEUID}",
          "GlobalRequestSettings":{
            "IncludeRequest": false
          },
          "GlobalRequestParameters":{
            "Term":"${termInMonths}"
          },
          "Requests":[
            {
              "Figures":{
                "Asset":{
                  "AnnualDistance":"${annualDistance}",
                  "OutstandingSettlement":0,
                  "PartExchange":0,
                  "ManualResidualValue":null,
                  "VATIncluded":true,
                  "VATQualifying":true,
                  "ExcludeVatAsDeposit":null,
                  "Extras":{},
                  "ValueAddedProducts":${accessoriesRequestObject}
                },
                "CashDeposit":${cashDeposit},
                "CashPrice":"${cashPrice}"
              },
              "Asset":{
                "Class":"motorbike",
                "Condition":"used",
                "CurrentOdometerReading":"${currentOdometerReading}",
                "EntityType":"PrivateIndividual",
                "Identity":"${capCodeValue === undefined ? vrmValue : capCodeValue}",
                "IdentityType":"${capCodeValue === undefined ? "VRM" : "RVC"}",
                "RegistrationDate":"${registrationDate}T00:00:00.000Z",
                "Source":1,
                "StockIdentity":null,
                "StockLocation":null,
                "CubicCentimetres":null,
                "RegistrationMark":"${vrmValue}"
              }
            }
          ]
        }
      ]
    }
  `
    const config = {
      baseURL: `https://quoteware3.ivendi.com`,
      headers: {'content-type': 'application/json'},
    };

    return axios.post(`/quotes`, data, config);
  } else {
    throw 'neither vrm nor capCode can be null'
  }
};

export const quotewareRequestForNewBike = (capCode,cashPrice ,annualDistance = DEFAULT_ANNUAL_DISTANCE, termInMonths= DEFAULT_TERM_IN_MONTHS, cashDeposit = DEFAULT_CASH_DEPOSIT, accessories=[], cfMoto) => {

  const USERNAME = process.env.IVENDI_USERNAME;
  let QUOTEEUID
  if (cfMoto === true) {
    QUOTEEUID = '523A055C-C45D-449B-A710-003B3E2F1ABC';
  } else {
    QUOTEEUID = process.env.IVENDI_QUOTEEUID;
  }
  const DEALERSHIP_EMAIL = process.env.IVENDI_DEALERSHIP_EMAIL;
  const DEALERSHIP_POSTCODE = process.env.IVENDI_DEALERSHIP_POSTCODE;

  if (capCode != null) {
    const capCodeValue = capCode.padEnd(20, " ");

    let accessoriesRequestObject = 'null'
    if (accessories && !!accessories.length) {
      const arrayAccessories = []
      accessories.forEach(accessory => {
        let accessoryObject = `
      {
        "GrossPrice":"${accessory.price}",
        "TypeCode": 13,
        "Description": "${accessory.name}",
        "TaxCode": 1
      }`;

        arrayAccessories.push(accessoryObject)
      })

      accessoriesRequestObject = `[${arrayAccessories.join(",")}]`
    }

    const data = `
    {
      "Credentials":{
        "Username":"${USERNAME}"
      },
      "DealershipEnrichment":{
        "Email": "${DEALERSHIP_EMAIL}",
        "Postcode": "${DEALERSHIP_POSTCODE}"
      },
      "QuoteRequests":[
        {
          "QuoteeUID":"${QUOTEEUID}",
          "GlobalRequestSettings":{
            "IncludeRequest": false
          },
          "GlobalRequestParameters":{
            "Term":"${termInMonths}"
          },
          "Requests":[
            {
              "Figures":{
                "Asset":{
                  "AnnualDistance":"${annualDistance}",
                  "OutstandingSettlement":0,
                  "PartExchange":0,
                  "ManualResidualValue":null,
                  "VATIncluded":true,
                  "VATQualifying":true,
                  "ExcludeVatAsDeposit":null,
                  "Extras":{},
                  "ValueAddedProducts":${accessoriesRequestObject}
                },
                "CashDeposit":${cashDeposit},
                "CashPrice":"${cashPrice}"
              },
              "Asset":{
                "Class":"motorbike",
                "Condition":"new",
                "CurrentOdometerReading":"0",
                "EntityType":"PrivateIndividual",
                "Identity":"${capCodeValue}",
                "IdentityType":"RVC",
                "RegistrationDate":null,
                "Source":1,
                "StockIdentity":null,
                "StockLocation":null,
                "CubicCentimetres":null,
                "RegistrationMark":null
              }
            }
          ]
        }
      ]
    }
  `

    const config = {
      baseURL: `https://quoteware3.ivendi.com`,
      headers: {'content-type': 'application/json'},
    };

    return axios.post(`/quotes`, data, config);
  } else {
    throw 'capCode cannot be null'
  }
};


//DealershipId
//

//How the ivendi widget generate logos
//https://d2bkdfyoj2xgsx.cloudfront.net/funderlogo/mot.jpg?maxheight=85

