exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basket-delivery-options-js": () => import("./../../../src/pages/basket-delivery-options.js" /* webpackChunkName: "component---src-pages-basket-delivery-options-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-bicycle-services-js": () => import("./../../../src/pages/bicycle-services.js" /* webpackChunkName: "component---src-pages-bicycle-services-js" */),
  "component---src-pages-bicycle-services-success-js": () => import("./../../../src/pages/bicycleServicesSuccess.js" /* webpackChunkName: "component---src-pages-bicycle-services-success-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-finance-checkout-accessories-js": () => import("./../../../src/pages/finance-checkout-accessories.js" /* webpackChunkName: "component---src-pages-finance-checkout-accessories-js" */),
  "component---src-pages-finance-checkout-data-js": () => import("./../../../src/pages/finance-checkout-data.js" /* webpackChunkName: "component---src-pages-finance-checkout-data-js" */),
  "component---src-pages-finance-checkout-product-js": () => import("./../../../src/pages/finance-checkout-product.js" /* webpackChunkName: "component---src-pages-finance-checkout-product-js" */),
  "component---src-pages-finance-checkout-summary-js": () => import("./../../../src/pages/finance-checkout-summary.js" /* webpackChunkName: "component---src-pages-finance-checkout-summary-js" */),
  "component---src-pages-home-delivery-js": () => import("./../../../src/pages/homeDelivery.js" /* webpackChunkName: "component---src-pages-home-delivery-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-payment-failure-js": () => import("./../../../src/pages/paymentFailure.js" /* webpackChunkName: "component---src-pages-payment-failure-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/paymentSuccess.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-reservation-success-js": () => import("./../../../src/pages/reservationSuccess.js" /* webpackChunkName: "component---src-pages-reservation-success-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-list-page-brand-brand-js": () => import("./../../../src/templates/productListPage/brand/brand.js" /* webpackChunkName: "component---src-templates-product-list-page-brand-brand-js" */),
  "component---src-templates-product-list-page-product-category-product-category-js": () => import("./../../../src/templates/productListPage/productCategory/productCategory.js" /* webpackChunkName: "component---src-templates-product-list-page-product-category-product-category-js" */),
  "component---src-templates-simple-product-js": () => import("./../../../src/templates/simpleProduct.js" /* webpackChunkName: "component---src-templates-simple-product-js" */),
  "component---src-templates-variable-product-js": () => import("./../../../src/templates/variableProduct.js" /* webpackChunkName: "component---src-templates-variable-product-js" */)
}

